import './App.css';
import MusicHouse from './components/music-house/MusicHouse';

function App() {
  return (
    <MusicHouse />
  );
}

export default App;
